<template>
  <div class="hotspot-container">
    <div class="head-box">
      <div class="time-filter">
        <div class="time-filter_label">生成时段</div>
        <!-- <el-select class="time-filter_select" size="mini" v-model="params.date" placeholder="请选择">
          <el-option v-for="item in currentListType" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select> -->
        <el-date-picker
          v-model="params.date"
          key="date"
          type="date"
          :format="'yyyy-MM-dd'"
          value-format="yyyy-MM-dd"
          :placeholder="`选择生成时段`"
          :picker-options="pickerOptions"
          :clearable="false"
          @change="handleDateChange"
        ></el-date-picker>
      </div>
      <div class="exact-time-filter">
        <div class="exact-time-filter_label">小时榜</div>
        <div class="exact-time-filter_check" ref="filterCheck">
          <div
            v-for="(item, index) in hours"
            :key="index"
            :class="['hour-button', { checked: item == currentHour }]"
            @click="checkHour(item)"
          >
            {{ item }}:00
          </div>
        </div>
      </div>
    </div>

    <div class="content-box" ref="contentBox">
      <el-scrollbar style="height: 100%" ref="scrollbar">
        <div class="hotspot-type">
          <el-radio-group class="radio-group" v-model="params.type" size="small" @change="getListByType">
            <!-- <el-radio-button label="hour">小时榜</el-radio-button> -->
            <el-radio-button label="1">实时热点榜</el-radio-button>
            <el-radio-button label="2">飙升热点榜</el-radio-button>
          </el-radio-group>
        </div>

        <div class="hotspot-main">
          <el-collapse v-for="(item, index) in hotspotList" :key="item.id" class="hotspot-main-item" @change="changeCollapse(item)">
            <el-collapse-item>
              <template slot="title">
                <div class="hotspot-main_header">
                  <div class="header-cover">
                    <i class="header-cover-rank" v-if="index < 99">{{ index + 1 }}</i>
                    <i class="header-cover-rank" v-else>...</i>
                    <img class="header-cover_img" :src="item.cover" alt="" />
                  </div>
                  <div class="header-info">
                    <div class="header-info_sentence">{{ item.sentence }}</div>
                    <div class="header-info_hot">
                      <div class="header-info_hot-detail">
                        <i class="hot-icon">
                          <svg
                            t="1724835033291"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="3570"
                            width="10"
                            height="10"
                          >
                            <path
                              d="M505.059556 1006.288593s697.837037 15.17037 273.066666-768.606815c0 0-45.511111 136.533333-70.769778 161.792 0 0-25.296593-267.984593-399.511703-399.473778 0 0 141.615407 273.066667-80.896 475.32563 0 0-25.296593-60.681481-60.681482-116.280889 0 0-353.962667 621.985185 338.792297 647.243852z"
                              fill="#999CB4"
                              p-id="3571"
                            ></path>
                          </svg>
                        </i>
                        <span>{{ item.hot_level }}</span>
                      </div>

                      <!-- <div class="header-info_hot-detail">
                        <i class="hot-icon">
                          <svg
                            t="1724917256009"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="4291"
                            width="16"
                            height="16"
                          >
                            <path
                              d="M457.387 152.462V409.6c0 38.684-29.583 68.267-65.991 68.267H147.91c-36.409 0-65.991-29.583-65.991-68.267V152.462c0-38.684 29.582-68.266 65.991-68.266h245.76c34.133 0 63.716 31.857 63.716 68.266z m147.91 325.405h245.76c36.41 0 65.992-29.583 65.992-68.267V152.462c0-38.684-29.582-68.266-65.991-68.266h-245.76c-36.41 0-65.991 29.582-65.991 68.266V409.6c2.275 36.409 29.582 68.267 65.99 68.267z m-213.901 68.266H147.91c-36.409 0-65.991 31.858-65.991 68.267v254.862c0 38.685 29.582 68.267 65.991 68.267h245.76c36.409 0 65.991-29.582 65.991-68.267V614.4c-2.275-36.409-31.858-68.267-68.266-68.267z m466.488 0H614.4c-36.409 0-65.991 31.858-65.991 68.267v254.862c0 38.685 29.582 68.267 65.991 68.267h245.76c36.409 0 65.991-29.582 65.991-68.267V614.4c-2.275-36.409-31.858-68.267-68.267-68.267z"
                              p-id="4292"
                              fill="#999CB4"
                            ></path>
                          </svg>
                        </i>
                        <span>所属分类：体育</span>
                      </div> -->
                    </div>
                  </div>
                </div>
              </template>
              <div class="hotspot-main_content">
                <div class="content-video">
                  <div class="content-video_label">相关视频({{ item.videoTotal || 0 }})</div>
                  <div class="content-video_list">
                    <div class="video-img" v-for="video in item.videos" :key="video.id">
                      <i class="video-play">
                        <svg
                          t="1724919437903"
                          class="icon"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          p-id="5452"
                          width="32"
                          height="32"
                          @click="toPlayVideo(video.share_url)"
                        >
                          <path
                            d="M882.734114 459.147258l0.024559-0.024559L244.016061 21.12718l-0.199545 0.188288C230.582097 8.748245 212.62819 1.014096 192.840518 1.014096c-40.704051 0-73.699536 32.66905-73.699536 72.996524 0 22.148439-0.954745 65.513086 0 64.572668l0 373.422851 0 393.071354c0 0.325411 0 25.249057 0 44.935422 0 40.302915 32.995485 72.972988 73.699536 72.972988 19.862373 0 37.892005-7.78429 51.125401-20.466124l0.050142 0.025583 638.742613-437.982216-0.024559-0.038886c13.886265-13.270235 22.549575-31.889291 22.549575-52.531424 0-0.050142 0-0.088004 0-0.150426 0-0.050142 0-0.11154 0-0.149403C905.28369 491.048829 896.620379 472.41647 882.734114 459.147258z"
                            p-id="5453"
                            fill="#ffffff"
                          ></path>
                        </svg>
                      </i>
                      <img :src="video.cover" :alt="video.title" />
                    </div>
                  </div>
                </div>
                <div class="content-filp">
                  <el-button
                    icon="el-icon-arrow-left"
                    circle
                    size="mini"
                    :disabled="!item.isPreVideo"
                    @click="getHotspotVideo(item, 'prev')"
                  ></el-button>
                  <el-button
                    icon="el-icon-arrow-right"
                    circle
                    size="mini"
                    :disabled="!item.isNextVideo"
                    @click="getHotspotVideo(item, 'next')"
                  ></el-button>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>

        <div class="hotspot-load">
          <span v-if="!loadMore" style="cursor: auto">没有更多作品</span>
          <span v-else @click="loadMoreData">点击加载更多</span>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { getFormatDate } from "@/utils/formatDate";
export default {
  name: "Hotspot",
  data() {
    return {
      // 请求参数
      params: {
        date: "",
        page: 1,
        limit: 10,
        type: "1",
        time: "",
      },
      hotspotList: [],
      hours: [], // 小时榜列表
      currentHour: "", // 当前选择的小时榜
      loading: false, // 页面加载动画
      loadMore: false, // 是否还有更多作品
      // isPreVideo: false, // 是否可以翻上一页
      // isNextVideo: true, // 是否可以翻下一页
      flag: true,
      pickerOptions: {
        firstDayOfWeek: 1, // 设置周一为一周的第一天
        disabledDate: (time) => {
          // 禁用未来的日期
          const today = new Date();

          return time.getTime() > today;
        },
      },
    };
  },
  computed: {
    // 获取算上今日最近30日的日期
    currentListType() {
      let date = new Date();
      // date.setMonth(date.getMonth() - 1);
      let list = [getFormatDate(date).split(" ")[0]];
      for (let i = 0; i < 29; i++) {
        date.setDate(date.getDate() - 1);
        list.push(getFormatDate(date).split(" ")[0]);
      }
      return list.map((item, index) => {
        return {
          value: item,
          label: item,
        };
      });
    },
  },
  watch: {
    "params.date": {
      handler(newval, oldVal) {
        this.params.date = newval;
        const dateArr = this.params.date.split("-"); // year month day
        const currentDate = new Date();
        let hour = 23;
        this.hours = [];
        if (currentDate.getDate() == dateArr[2] && currentDate.getMonth() + 1 == dateArr[1]) {
          hour = currentDate.getHours();
        }

        for (let i = 0; i <= hour; i++) {
          let addHour = "";
          addHour = i < 10 ? `0${i}` : i.toString();
          this.hours.push(addHour);
        }

        this.currentHour = this.hours.at(-1);
        this.params.time = `${this.currentHour}:00`;

        this.$nextTick(() => {
          this.setContentHeight();
        });

        this.getHotspotList();
      },
      immediate: true,
    },
  },
  mounted() {
    this.initDate();
    this.setContentHeight(); // 设置内容区高度
    window.addEventListener("resize", this.setContentHeight); // 监听页面变化
    // this.getHotspotList();
  },
  methods: {
    // 初始化时间，默认为当天
    initDate() {
      const currentDate = this.currentListType;
      this.params.date = currentDate[0].value;
    },
    // 获取创作热点
    getHotspotList(isAppend = false) {
      if (this.flag) {
        this.flag = false;
        this.params.page = isAppend ? this.params.page + 1 : 1;

        if (!isAppend && this.$refs.scrollbar) {
          const scrollbarWrap = this.$refs.scrollbar.wrap;
          if (scrollbarWrap) {
            scrollbarWrap.scrollTop = 0;
          }
        }

        // tiktokGetHotsPotList(this.params)
        this.$httpStudent.axiosGetBy(
          this.$api.billborardHotSearch,
          this.params,
          (res) => {
            if (res.code === 200) {
              // isPreVideo、isNextVideo，判断相关视频前后翻页按钮状态; videoParams 相关视频请求参数
              res.data.list.forEach((item) => {
                const params = {
                  id: item.id,
                  page: 1,
                  limit: 10,
                };

                item.isPreVideo = false;
                item.isNextVideo = true;
                item.videoParams = params;
              });
              this.hotspotList = isAppend ? this.hotspotList.concat(res.data.list) : res.data.list;

              if (res.data.total === 0 || this.hotspotList.length >= res.data.total) this.loadMore = false;
              else this.loadMore = true;
            }
            this.flag = true;
          },
          (error) => {
            this.flag = true;
          }
        );
      }
    },

    handleDateChange() {},

    // 选择时间
    checkHour(hour) {
      this.currentHour = hour;
      this.params.time = `${this.currentHour}:00`;
      this.getHotspotList();
    },

    // 切换类型
    getListByType() {
      this.getHotspotList();
    },

    // 设置内容区高度
    setContentHeight() {
      const filterHeight = this.$refs.filterCheck.offsetHeight;
      console.log("debugger height: ", filterHeight);
      this.$refs.contentBox.style.height = `calc( 100vh - ${filterHeight}px - 148px)`;
    },

    // 播放视频
    toPlayVideo(url) {
      // console.log("debugger play video");
      window.open(url);
    },

    // 加载更多列表数据
    loadMoreData() {
      this.getHotspotList(true);
    },

    // 面板展开
    changeCollapse(hotspot) {
      const changeHotspot = this.hotspotList.find((item) => {
        return item.id === hotspot.id;
      });
      if (changeHotspot.expand) {
        this.$set(changeHotspot, "expand", false);
        return;
      }
      this.$set(changeHotspot, "expand", true);

      if (changeHotspot.videos && changeHotspot.videos.length > 0) return;

      this.getHotspotVideo(changeHotspot);
    },

    // 面板展开时 - type(none直接查询 / prev上一页 / next下一页)
    getHotspotVideo(hotspot, type = "none") {
      if (type === "prev") this.$set(hotspot.videoParams, "page", hotspot.videoParams.page - 1);
      else if (type === "next") this.$set(hotspot.videoParams, "page", hotspot.videoParams.page + 1);

      // tiktokGetHotsPotVideoList(hotspot.videoParams)
      this.$httpStudent.axiosGetBy(this.$api.billborardHotSearchVideo, hotspot.videoParams, (res) => {
        if (res.code === 200) {
          this.$set(hotspot, "videoTotal", res.data.total); // 视频总数量
          this.$set(hotspot, "videos", res.data.list); // 视频列表

          // 判断按钮状态
          if (hotspot.videoTotal === 0 || hotspot.videoTotal <= 10) {
            this.$set(hotspot, "isPreVideo", false);
            this.$set(hotspot, "isNextVideo", false);
          } else if (hotspot.videoParams.page == 1) {
            this.$set(hotspot, "isPreVideo", false);
            this.$set(hotspot, "isNextVideo", true);
          } else if (hotspot.videoParams.page * hotspot.videoParams.limit >= hotspot.videoTotal) {
            this.$set(hotspot, "isPreVideo", true);
            this.$set(hotspot, "isNextVideo", false);
          } else {
            this.$set(hotspot, "isPreVideo", true);
            this.$set(hotspot, "isNextVideo", true);
          }
        }
      });
    },
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.setContentHeight);
  },
};
</script>

<style scoped lang="scss">
.hotspot-container {
  min-height: 200px;
  background-color: #fff;
  padding: 24px;

  .head-box {
    [class$="_label"] {
      /* 所有类名以 '-label' 结尾的样式 */
      font-weight: 400;
      font-size: 14px;
      color: #252632;
      line-height: 16px;
      font-style: normal;
    }
    .time-filter {
      float: left;
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;
      z-index: 10;

      // ::v-deep .el-input__inner {
      //   background-color: #f2f2f4;
      //   color: #252632;
      //   border: none;
      //   padding: 6px 10px 6px 16px;
      // }

      // ::v-deep .el-select .el-input .el-select__caret {
      //   color: #252632;
      // }

      ::v-deep .el-date-editor.el-input,
      ::v-deep .el-date-editor.el-input__inner {
        width: 135px;
      }

      ::v-deep .el-input__inner {
        background-color: #f2f2f4;
        color: #252632;
        border: none;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
      }

      ::v-deep .el-input__icon {
        line-height: 28px;
      }
    }

    .exact-time-filter {
      &_label {
        float: left;
        margin-left: 26px;
        height: 28px;
        line-height: 28px;
      }

      &_check {
        position: relative;

        .hour-button {
          position: relative;
          display: inline-block;
          padding: 6px 24px;
          background-color: #f2f2f4;
          border-radius: 4px;
          margin-left: 20px;
          margin-bottom: 16px;
          font-size: 14px;
          color: rgba(37, 38, 50, 0.6);
          line-height: 16px;
          cursor: pointer;

          &.checked {
            color: #594fee;
            background-color: #ecebff;
          }

          &:hover {
            color: #594fee;
          }

          &:not(.hour-button:nth-child(1))::before {
            content: "";
            position: absolute;
            border-top: 2px dotted rgba(37, 38, 50, 0.4);
            width: 20px;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .content-box {
    min-height: 300px;
    // max-height: calc(100vh - 230px);
    box-sizing: border-box;
    background-color: #fff;
    border-top: 1px solid #eaebed;
    padding-top: 24px;
    ::v-deep .el-scrollbar .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    .hotspot-type {
      ::v-deep .el-radio-button--small .el-radio-button__inner {
        padding: 6px 24px;
      }
      ::v-deep .el-radio-button__inner {
        background-color: #f2f2f4;
        border: none !important;
      }
      ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        box-shadow: none;
        background-color: #594fee;
      }

      ::v-deep .el-radio-button__inner:hover {
        color: #594fee !important;
      }

      ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner:hover {
        color: #ffffff !important;
      }
    }

    .hotspot-main {
      margin-top: 24px;
      min-height: 50px;
      // background-color: #f2f2f4;

      /* collspace header */
      &_header {
        display: flex;
        align-items: center;
        height: 60px;
        gap: 12px;
        overflow: hidden;

        .header-cover {
          width: 60px;
          height: 60px;
          border-radius: 4px;
          overflow: hidden;
          position: relative;

          i {
            position: absolute;
            width: 16px;
            height: 16px;
            border-radius: 4px 0px 8px 0px;
            top: 0;
            left: 0;
            border-right: 1px solid #f7f9fb;
            border-bottom: 1px solid #f7f9fb;
            font-style: normal;
            text-align: center;
            line-height: 16px;
            color: #fff;
            font-size: 10px;
            background-color: #929399;
          }

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            box-sizing: border-box;
            // border: 1px solid #000;
          }
        }

        .header-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          line-height: 16px;
          overflow: hidden;

          &_sentence {
            font-size: 14px;
            font-weight: 400;
            color: #000;
            white-space: nowrap; /* 防止文本换行 */
            overflow: hidden; /* 隐藏超出的部分 */
            text-overflow: ellipsis; /* 用省略号表示被截断的文本 */
          }

          &_hot {
            font-size: 12px;
            color: #777e87;
            line-height: 14px;
            display: flex;
            align-items: center;
            gap: 25px;

            &-detail {
              display: flex;
              align-items: center;
              gap: 3px;

              .hot-icon {
                width: 18px;
                height: 18px;
                background-color: #e8eaef;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg.icon {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
        }
      }

      /* special header i and icon */
      .hotspot-main-item:nth-child(1) {
        .hotspot-main_header .header-cover-rank {
          background-color: #ff2c55;
        }
        .header-info_hot-detail:nth-child(1) .hot-icon {
          background-color: #f9dde0 !important;
        }
        .header-info_hot-detail:nth-child(1) .hot-icon svg path {
          fill: #ff2c55;
        }
      }

      .hotspot-main-item:nth-child(2) {
        .hotspot-main_header .header-cover-rank {
          background-color: #ff851d;
        }
        .header-info_hot-detail:nth-child(1) .hot-icon {
          background-color: #f9dfda !important;
        }
        .header-info_hot-detail:nth-child(1) .hot-icon svg path {
          fill: #ff7352;
        }
      }

      .hotspot-main-item:nth-child(3) {
        .hotspot-main_header .header-cover-rank {
          background-color: #face15;
        }
        .header-info_hot-detail:nth-child(1) .hot-icon {
          background-color: #f8e7d1 !important;
        }
        .header-info_hot-detail:nth-child(1) .hot-icon svg path {
          fill: #ffa132;
        }
      }

      /* collspace content */
      &_content {
        display: flex;
        justify-content: space-between;

        .content-video {
          // width: 65%;
          // height: 50px;
          // background-color: #000;
          padding: 16px 0;
          border-top: 1px solid #eaebed;

          &_label {
            font-family: PingFang SC, PingFang SC;
            font-size: 12px;
            color: #111111;
            line-height: 14px;
            margin-bottom: 16px;
          }

          &_list {
            display: flex;
            gap: 18px;

            .video-img {
              position: relative;
              width: 68px;
              height: 90px;
              border-radius: 4px;
              overflow: hidden;

              box-sizing: border-box;

              .video-play {
                position: absolute;
                width: 20px;
                height: 24px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                svg.icon {
                  width: 23px;
                  height: 28px;
                  cursor: pointer;
                }
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }

        .content-filp {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          padding-top: 16px;
          flex-wrap: wrap;

          ::v-deep(.el-button) {
            background-color: #e5e7ec;
            color: #7b828b;
          }

          ::v-deep(.el-button.is-disabled) {
            background-color: #e5e7ec;
            color: #cbced4;
          }
        }
      }

      /* elementui style */
      ::v-deep .el-collapse-item {
        margin-bottom: 16px;
      }

      ::v-deep .el-collapse-item__arrow {
        display: none; // 右侧icon不需要
      }

      ::v-deep .el-collapse-item__header {
        background-color: #f7f9fb;
        padding: 16px 24px;
        border: none !important;
      }

      ::v-deep .el-collapse {
        border: none !important;
      }

      ::v-deep .el-collapse-item__content {
        background-color: #f7f9fb;
        // background-color: #f2f2f4;
        padding: 0 24px;
      }

      ::v-deep .el-button {
        background-color: #e5e7ec;
        border: none;
        color: #7b828b;
      }

      ::v-deep .el-button:focus,
      .el-button:hover {
        color: #594fee;
      }
    }

    /* load style */
    .hotspot-load {
      display: flex;
      justify-content: center;
      padding: 25px 0;

      span {
        font-weight: 400;
        font-size: 16px;
        color: #888888;
        line-height: 19px;
        cursor: pointer;
      }
    }
  }
}
</style>